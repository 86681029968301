import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from "../../flow-ui-widgets/ContactInfo";

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='Send me a message 👋'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
